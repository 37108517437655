import { StyleSheet, Text, View } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import Statistics from '../analyticsTabels/Statistics';
import HeatMap from '../analyticsTabels/HeatMap';
import InteractionsMap from '../analyticsTabels/InteractionsMap';
import { ScrollSync } from 'react-scroll-sync';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import WordsMap from '../analyticsTabels/WordsMap';
import WordMessages from "../analyticsTabels/WordMessages";
import { useSelector } from 'react-redux';
import FullScreenPopup from '../../../../Elements/FullScreenPopup';
import QuestionnaireMap from '../analyticsTabels/QuestionnaireMap';

export default function ComparedAnalytics({ stopPlayScroll, setIsShot, isShot, setMaximizeType, statisticsRef, heatMapRef, interactionRef, wordCloudRef, questionnaireRef, num }) {
  const timeUnitsSelector = useSelector(state => state.swimlane.timeUnints);

  const { widthS, heightS } = UseResponsiveScreen();
  const [position, setPosition] = useState();
  const [timeUnits, setTimeUnits] = useState();

  const [wordsMessagesReal, setWordsMessagesReal] = useState([]);
  const [wordReal, setWordReal] = useState();
  const [wordsMessagesExpected, setWordsMessagesExpected] = useState([]);
  const [wordExpected, setWordExpected] = useState();
  const [maximize, setMaximize] = useState(false);
  const [hoverType, setHoverType] = useState();

  const tables = {
    statistics: (flag) => <View style={{ flexDirection: "row", zIndex: 5 }} nativeID='statistics' >
      <Statistics refShot={statisticsRef} isShot={isShot} maximize={flag} hoverType={hoverType} setHoverType={setHoverType} setMaximize={() => setMaximizeType(maximize, "statistics", setMaximize)} type={"compared"} position={position} setPosition={setPosition} timeUnits={timeUnits} setTimeUnits={setTimeUnits} stopPlayScroll={stopPlayScroll} />
      <Statistics refShot={statisticsRef} isShot={isShot} maximize={flag} hoverType={hoverType} setHoverType={setHoverType} setMaximize={() => setMaximizeType(maximize, "statistics", setMaximize)} type={"compared"} real={true} position={position} setPosition={setPosition} timeUnits={timeUnits} setTimeUnits={setTimeUnits} stopPlayScroll={stopPlayScroll} />
    </View>,
    heat: (flag) => <View style={{ flexDirection: "row", justifyContent: "space-between", marginBottom: heightS * 20, zIndex: 4, height: maximize === "heat" ? "100%" : "" }} nativeID='heatMap'>
      <HeatMap isShot={isShot} refShot={heatMapRef} maximize={flag} setMaximize={() => setMaximizeType(maximize, "heat", setMaximize)} type={"compared"} position={position} setPosition={setPosition} timeUnits={timeUnits} setTimeUnits={setTimeUnits} stopPlayScroll={stopPlayScroll} />
      <HeatMap isShot={isShot} refShot={heatMapRef} maximize={flag} setMaximize={() => setMaximizeType(maximize, "heat", setMaximize)} type={"compared"} real={true} position={position} setPosition={setPosition} timeUnits={timeUnits} setTimeUnits={setTimeUnits} stopPlayScroll={stopPlayScroll} />
    </View>,
    interaction: (flag) => <View style={{ flexDirection: "row", zIndex: 3, height: maximize ? "100%" : "" }} nativeID='interactionMap'>
      <InteractionsMap isShot={isShot} refShot={interactionRef} maximize={flag} setMaximize={() => setMaximizeType(maximize, "interaction", setMaximize)} type={"compared"} real={true} position={position} setPosition={setPosition} timeUnits={timeUnits} setTimeUnits={setTimeUnits} stopPlayScroll={stopPlayScroll} />
    </View>,
    word: (flag) =>
      <View style={styles.wordMaps} nativeID="wordsMap">
        <View style={[styles.wordMap, { minHeight: "41vw" }]}>
          <WordsMap isShot={isShot} refShot={wordCloudRef} maximize={flag} wordsMessages={wordsMessagesExpected} word={wordExpected} setMaximize={() => setMaximizeType(maximize, "word", setMaximize)} type={"compared"} setWordsMessages={setWordsMessagesExpected} setWord={setWordExpected} position={position} setPosition={setPosition} stopPlayScroll={stopPlayScroll} />
          <WordsMap isShot={isShot} refShot={wordCloudRef} maximize={flag} wordsMessages={wordsMessagesReal} word={wordReal} setMaximize={() => setMaximizeType(maximize, "word", setMaximize)} real={true} type={"compared"} setWordsMessages={setWordsMessagesReal} setWord={setWordReal} position={position} setPosition={setPosition} stopPlayScroll={stopPlayScroll} />
        </View>
        {(wordsMessagesExpected.length > 0 || wordsMessagesReal.length > 0) &&
          <View style={styles.wordMap} >
            <WordMessages type={"compared"} wordsMessages={wordsMessagesExpected} word={wordExpected?.text} stopPlayScroll={stopPlayScroll} />
            <WordMessages type={"compared"} wordsMessages={wordsMessagesReal} word={wordReal?.text} stopPlayScroll={stopPlayScroll} />
          </View>}
      </View>,
    questionnaire: (flag) => <QuestionnaireMap setIsShot={setIsShot} isShot={isShot} stopPlayScroll={stopPlayScroll} refShot={questionnaireRef} />
  }

  useEffect(() => {
    setTimeUnits(timeUnitsSelector);
  }, [timeUnitsSelector]);

  const returnMap = (num) => {
    switch (num) {
      case 1: return tables.statistics()
      case 2: return tables.heat()
      case 3: return tables.interaction()
      case 4: return tables.word()
      case 5: return tables.questionnaire()
    }
  }

  return (
    <View >
      <ScrollSync>
        <>
          {returnMap(num)}
        </>
      </ScrollSync>
      {maximize &&
        <FullScreenPopup
          children={
            <ScrollSync>
              {tables[maximize](true)}
            </ScrollSync>
          }
          flag={true}
          setFlag={() => setMaximize(false)}
        />}
    </View>
  )
}

const styles = StyleSheet.create({
  wordMaps: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "3vw",
  },
  wordMap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    flex: 1
  },
})
import React from 'react';
import { Tooltip } from 'antd';
import { StyleSheet, View, Text } from 'react-native';
import { useState, useEffect, useRef, useCallback } from 'react';

const FullTextTooltip = ({ children, text, length }) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const textRef = useRef(null);

    const checkOverflow = useCallback(() => {
        const { clientWidth, scrollWidth } = textRef.current;
        const isOverflow = children?.props?.children?.indexOf("...") >= 0 || scrollWidth > clientWidth || (length && children?.props?.children?.length > length);
        console.log(textRef);
        setIsOverflowed(isOverflow);
    }, [textRef.current, text]);

    useEffect(() => {
        if (textRef.current) {
            checkOverflow();
        }
    }, [children, text]);

    return (
        <Tooltip
            title={isOverflowed && text}
            overlayStyle={{ whiteSpace: 'pre-line' }}
            // overlayInnerStyle={children?.props?.style ? [{color: }, children?.props?.style[1]] : {}}
            mouseLeaveDelay={0.1}
            zIndex={10000}
        >
            <View
                style={styles.textContainer}
            >
                {React.cloneElement(children, { ref: textRef })}
            </View>
        </Tooltip>
    );
};

const styles = StyleSheet.create({
    textContainer: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

export default FullTextTooltip;

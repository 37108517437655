import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  View,
  Pressable,
  Text,
  TextInput,
  Dimensions,
  Image,
} from "react-native";
import { listenerAdd, listenerAddBT } from "../../AddListener";
import { AntDesign, Ionicons, FontAwesome } from "@expo/vector-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  USER_GAME_NAME,
} from "../../config.inc";
import ShortenWord from "../modules/ShortenWord";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import PlayerSelect from "./PlayerSelect";
import FullTextTooltip from "./FullTextTooltip";

export default function SearchInput({
  styleType,
  newMail,
  styles,
  value,
  setValue,
  system,
  err,
  playersListProp,
  isEditing = true,
  addNew
}) {

  const { widthS, heightS } = UseResponsiveScreen();

  const lang = useSelector((state) => state.setting.setting.lang);
  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const playerPermission = useSelector((state) => state.gameInfoArr.permission);
  const playersState = useSelector((state) => state.gameInfoArr.gameInfoArr.players);


  const btnRef = useRef(null);
  const popupRefList = useRef(null);
  const inputRef = useRef(null);

  const [openList, setOpenList] = useState(false);
  const [allOrReal, setAllOrReal] = useState(""); //styleType.type == "share" ? "all" :
  const [players, setPlayers] = useState([]);
  const [phraseToSearch, setPhraseToSearch] = useState("");
  const [playersList, setPlayersList] = useState();

  const [field, setField] = useState("");
  const [fieldId, setFieldId] = useState("");

  const setFileldsTypes = (styleType) => {
    switch (styleType) {
      case "from":
        setField("from");
        setFieldId("from_id");
        return
      case "to":
        setField("to_list");
        setFieldId("to_list_ids");
        return
      case "cc":
        setField("cc_list");
        setFieldId("cc_list_ids");
        return
      case "bcc":
        setField("bcc_list");
        setFieldId("bcc_list_ids");
        return
      case "share":
        setField("share");
        setFieldId("share_ids");
        return
      case "event":
        setField("event");
        setFieldId("event_id");
        return
      case "platform":
        setField("platform_type");
        return
    }
  }

  const sortNames = (arr, arrIndex) => {
    let newArr = [];
    if (styleType.check) {
      arr = arr?.filter((x) => { return x.id != sessionStorage.getItem("id_in_game") })
    }

    if (arrIndex?.length > 0) {
      let index = 0;
      let names = "";
      arrIndex?.length > 0 && arrIndex.forEach((j, i) => {
        names = j.split(',');
        for (let t = 0; t < names.length - 1; t++) {
          newArr[index] = names[t];
          index++;
        }
      });
      newArr?.map((j, i) => {
        arr.map((p) => {
          if (p.name_for_game == j) {
            newArr[i] = p;
          }
        })
      })
    } else {
      if (styleType.type === "event") {
        newArr = [...arr];
      } else {
        arr?.map((e, i) => {
          newArr[i] = e.name_for_game.toUpperCase();
        })
        newArr.sort();
        newArr.map((j, i) => {
          arr.map((p) => {
            if (p.name_for_game.toUpperCase() == j) {
              newArr[i] = p;
            }
          })
        })
      }
    }
    return (newArr);
  }

  const playerFrom = () => {
    return (
      <Text
        selectable={false}
        style={[
          styles.label_text,
          { direction: lang === "he" ? "rtl" : "ltr" },
        ]}
      >
        {sessionStorage.getItem(USER_GAME_NAME)}
      </Text>
    )
  }

  const focusInput = () => {
    inputRef?.current?.focus();
  }

  const search = () => {
    if (phraseToSearch !== "") {
      if (styleType.type !== "event") {
        let playersToSearch = [];
        let arrIndex = [];
        let index = 0;
        players.filter((x) => { if (styleType.type !== "from") { return x.id != sessionStorage.getItem("id_in_game") } else { return true; } }).map((e, i) => {
          index = e.name_for_game.toLowerCase().indexOf(phraseToSearch.toLowerCase());
          if (index >= 0) {
            playersToSearch.push(e);
            arrIndex[index]?.length > 0 ?
              arrIndex[index] += e.name_for_game + ',' :
              arrIndex[index] = e.name_for_game + ','
          }
        })

        setPlayersList(sortNames(playersToSearch, arrIndex));
      } else {
        let playersToSearch = [];
        playersToSearch = players.filter((e) => e.name.toLowerCase().indexOf(phraseToSearch.toLowerCase()) === 0);
        setPlayersList(sortNames(playersToSearch));
      }
    } else {
      setPlayersList(sortNames(players));
    }
  }
  const removeItemFromString = (theString, theIteam) => {
    return theString?.indexOf(",") === -1 ? "" : theString?.split(',').filter(item => item !== theIteam).join(',');
  }
  const removePlayer = (setValue, name, id) => {
    setValue({ ...value, [field]: removeItemFromString(value[field], name), [fieldId]: removeItemFromString(value[fieldId], id) })
  }

  const printItems = (valuePrint, ids, flag) => {
    if (newMail) {
      switch (styleType.type) {
        case "from":
          return (
            <>
              {value[field] && value[field] !== "" &&
                <View style={[styles.viewItem, flag && styleType.shadow]}>
                  <Text
                    selectable={false}
                    onClick={() => { isEditing || (!isEditing && !value?.id?.length > 0) ? setOpenList(!openList) : null }}
                    style={[
                      styles.label_text_input,
                      {
                        direction: lang === "he" ? "rtl" : "ltr",
                        transition: "all 0.3s",
                        whiteSpace: "nowrap",

                      },
                      styleType.dark && !styleType.table && { color: color.infoHeaderNavText, flexDirection: "inherit" }
                    ]}
                  >
                    {
                      ShortenWord(valuePrint, styleType.dark ? 45 : styleType.social ? 20 : 45)}
                  </Text>
                  <Pressable style={styles.x_item} onPress={() => setValue({ from: "", from_id: "" })}>
                    <Text>X</Text>
                  </Pressable>
                </View>}
              {input(addNew)}
            </>
          );
        case "to":
        case "cc":
        case "bcc":
          return <View style={[styles.viewItems, flag && styleType.shadow]} nativeID="mail-players">
            {(() => {
              if (value[field] && value[field] !== "" && valuePrint) {
                return valuePrint.split(",").map((e, i) => {
                  if (e.length === 0) return null;
                  return (
                    <View style={[styles.viewItem, styles.viewItemWrap]} key={i}>
                      <FullTextTooltip text={e}>
                        <Text
                          selectable={false}
                          numberOfLines={1}
                          onClick={() => { isEditing || (!isEditing && !value?.id?.length > 0) ? setOpenList(!openList) : null }}
                          style={[
                            styles.label_text_input,
                            {
                              direction: lang === "he" ? "rtl" : "ltr",
                              transition: "all 0.3s",
                              whiteSpace: "nowrap",

                            },
                            styleType.dark && !styleType.table && { color: color.infoHeaderNavText, flexDirection: "inherit" }
                          ]}
                        // numberOfLines={1}
                        >
                          {ShortenWord(e, 25)}
                        </Text>
                      </FullTextTooltip>
                      <Pressable style={styles.x_item} onPress={() => removePlayer(setValue, e, Array.isArray(ids) ? ids[i]?.value : ids?.split(",")[i])}>
                        <Text>X</Text>
                      </Pressable>
                    </View>
                  )
                })
              }
            })()}
            {input()}
          </View>
        case "event":
          return (
            <>
              {value[field] && value[field] !== "" && <View style={[styles.viewItem, flag && styleType.shadow]}>
                <View style={[styles.eventColor, { backgroundColor: playersListProp.find((e) => e.id === value[fieldId])?.color }]} />
                <FullTextTooltip text={valuePrint}>
                  <Text
                    selectable={false}
                    numberOfLines={1}
                    onClick={() => { isEditing || (!isEditing && !value?.id?.length > 0) ? setOpenList(!openList) : null }}
                    style={[
                      styles.label_text_input,
                      {
                        direction: lang === "he" ? "rtl" : "ltr",
                        transition: "all 0.3s",
                        whiteSpace: "nowrap",

                      },
                      styleType.dark && !styleType.table && { color: color.infoHeaderNavText, flexDirection: "inherit" }
                    ]}
                  >
                    {valuePrint}
                  </Text>
                </FullTextTooltip>
                <Pressable style={styles.x_item} onPress={() => setValue({ event: "", event_id: "", eventColor: "" })}>
                  <Text>X</Text>
                </Pressable>
              </View>}
              {input(addNew)}
            </>
          );
        case "platform":
          let current = playersListProp?.find((e) => e.value === value?.platform_type);
          return (
            <>
              {value[field] && <View style={[styles.viewItem, styles.viewItemPlatform, flag && styleType.shadow]}>
                <Image source={{ uri: current?.icon }} style={styles.logoIconPlatform(true)} />
                <FullTextTooltip text={current?.label}>
                  <Text
                    selectable={false}
                    numberOfLines={1}
                    onClick={() => { isEditing || (!isEditing && !value?.id?.length > 0) ? setOpenList(!openList) : null }}
                    style={[
                      styles.label_text_input,
                      {
                        direction: lang === "he" ? "rtl" : "ltr",
                        transition: "all 0.3s",
                        whiteSpace: "nowrap",

                      },
                      styleType.dark && !styleType.table && { color: color.infoHeaderNavText, flexDirection: "inherit" }
                    ]}
                  >
                    {current?.label}
                  </Text>
                </FullTextTooltip>
              </View>}
            </>
          )
      }
    } else {
      return (
        <>
          <FullTextTooltip text={valuePrint}>
            <Text
              selectable={false}
              onClick={() => { isEditing || (!isEditing && !value?.id?.length > 0) ? setOpenList(!openList) : null }}
              style={[
                styles.label_text_input,
                {
                  direction: lang === "he" ? "rtl" : "ltr",
                  transition: "all 0.3s",
                  whiteSpace: "nowrap",

                },
                styleType.dark && { color: color.infoHeaderNavText, flexDirection: "inherit" }
              ]}
              numberOfLines={1}
            >
              {ShortenWord(valuePrint, styleType.dark ? 45 : styleType.social ? 20 : 45)}
            </Text>
          </FullTextTooltip>
          {input()}
        </>
      )
    }
  }

  const backgroundInput = () => {
    if (newMail && openList && !styleType?.table) {
      return background.mailPopup;
    } else if (styleType.social) {
      return background.socialInput;
    } else if (styleType.dark && !styleType?.length) {
      return background.infoBody;
    } else {
      return "";
    }
  }

  useMemo(() => {
    if (!openList) {
      setPhraseToSearch("");
    }
  }, [openList]);

  useEffect(() => {
    if (players?.length > 0 && styleType.type !== "platform") {
      search();
    }
  }, [phraseToSearch, players])


  const searchBar = () => {
    return (
      <>
        <Pressable
          ref={btnRef}
          disabled={!isEditing || (!isEditing && !value?.id?.length > 0)}
          onPress={() => {
            if (isEditing || (!isEditing && !value?.id?.length > 0)) {
              setOpenList(!openList);
              focusInput();
            }
          }}
          style={[
            styles.search_bar_pressable,
            err && (!value[fieldId]?.length > 0 || !value[field]?.length > 0) && styleType.type !== "cc" ? styles.mail_error : "",
            styleType.dark && {
              color: color.infoHeaderNavText,
              backgroundColor: newMail ? "" : background.infoBody,
              // height: styleType?.table ? "" : heightS * 25,
              // width: styleType?.table ? "100%" : widthS * 10,
            },
          ]}
        >
          <View
            style={[
              openList ? styles._btn_open : styles._btn,
              {
                backgroundColor: backgroundInput(),
                transition: "all 0.3s",
                flexDirection: "row",
              },
            ]}
            onClick={() => { isEditing || (!isEditing && !value?.id?.length > 0) ? setOpenList(!openList) : null }}
          >
            <View
              style={[
                styles._btn_head,
                styleType?.type === "platform" && styles._btn_head_platform,
              ]}
            >
              {printItems(value[field], value[fieldId], openList)}
              {((styleType.table || (!styleType.dark && !newMail)) || styleType.showCaret) && (
                <AntDesign
                  name="caretdown"
                  onClick={() => { isEditing || (!isEditing && !value?.id?.length > 0) ? setOpenList(!openList) : null }}
                  style={[
                    styles.arrow,
                    lang === "he" ? { left: 0 } : { right: 0 },
                    { transform: [{ rotate: openList ? "180deg" : "0" }], transition: "all .5s", }
                  ]}
                  size={styleType.showCaret ? null : widthS * 8}
                  color={styleType.social ? color.socialShareWith : (styleType?.table || styleType.showCaret) ? "white" : "black"}
                />
              )}
            </View>
            {openList && (
              <View
                style={[
                  styles._btn_body,
                  {
                    position: openList ? "absolute" : "",
                    top: openList ? "100%" : 0,
                    backgroundColor: styleType.dark ? background.infoBody : background.mailPopup,
                  },

                ]}
              >
                {openList && (
                  <PlayerSelect
                    newMail={newMail}
                    playersList={playersList}
                    playersListProp={playersListProp}
                    value={value}
                    setValue={setValue}
                    styleType={styleType}
                    system={system}
                    popupRefList={popupRefList}
                    close={() => { setOpenList(!openList) }}
                    allOrReal={allOrReal}
                    setAllOrReal={setAllOrReal}
                    field={field}
                    fieldId={fieldId}
                    phraseToSearch={phraseToSearch}
                    setPhraseToSearch={setPhraseToSearch}
                    styles={styles}
                    focusInput={focusInput}
                  />
                )}
              </View>
            )}
          </View>
        </Pressable>
      </>

    )
  }

  const input = (addNew) => {
    if (!openList) return;
    return (
      <TextInput
        nativeID="animation-new"
        autoFocus={openList ? true : false}
        ref={inputRef}
        value={phraseToSearch}
        onBlur={(e) => {
          if (styleType?.table && e.target?.value?.length > 1 && addNew) {
            addNew(e.target.value);
            setPhraseToSearch("")
          }
        }}
        style={[
          styles.label_text_input,
          { flex: 1, outline: "none", },
          newMail && { margin: "0.2vw", width: `${0.5 * (phraseToSearch?.length || 2)}vw` },
          styleType.dark && { color: color.infoHeaderNavText, flexDirection: "inherit", width: widthS * 10 }
        ]}
        onClick={() => { !openList && (isEditing || (!isEditing && !value?.id?.length > 0)) && value[field] == "" ? setOpenList(!openList) : null }}
        onChangeText={(text) => {
          setPhraseToSearch(text);
          search();
        }}
      />
    )
  }

  useMemo(() => {
    setFileldsTypes(styleType?.type);
    if (playersState?.length > 0 && playerPermission && styleType.type !== "event" && styleType.type !== "platform") {
      let filterPlayers;
      if (system) {
        filterPlayers = playersState.filter((e) => e.permission_for_game === "1");
        setPlayers(sortNames(playerPermission === "1" ? playersState : filterPlayers));
      } else {
        filterPlayers = playersState.filter((e) => e.permission_for_game !== "1");
        setPlayers(sortNames(filterPlayers));
      }
    }
    if (playersListProp?.length > 0) {
      setPlayers(playersListProp)
    }
  }, [playersState, system, playerPermission, styleType.type, playersListProp])

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      listenerAddBT(popupRefList, btnRef, openList, () => {
        setPhraseToSearch("");
        setOpenList(false);
      })
    );
  }, [openList]);


  return (
    <>
      <View nativeID={openList ? "filter-shadow" : ""} style={styles._container ? styles._container : [{ flex: 5.5 }]}>
        {(styleType.type == "from" && playerPermission !== "1") || (styleType.type == "from" && system) ? (
          playerFrom()
        ) : searchBar()}
      </View>
    </>
  )
}

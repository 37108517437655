import { StyleSheet, Text, View, Pressable, TouchableOpacity } from 'react-native'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ScrollSyncPane } from 'react-scroll-sync';
import UseResponsiveSize from '../../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import { useDispatch, useSelector } from 'react-redux';
import { AnalyticstimesContext } from '../analyticsFunction/Analyticstimes';
import InfiniteScroll from 'react-infinite-scroller';
import Rangeslider from '../../../../Elements/Rangeslider';
import { vwToPx } from '../../../../modules/ConvertPXToVw';
import { MaterialIcons } from '@expo/vector-icons';

export default function TimesSlider({ type, scrollRef, conatinerRef, countItems, id, flag, position, setPosition, real }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const timeRef = useRef(null);

    const dispatch = useDispatch();

    const {
        times,
        timesReal,
        timesCompared,
        // position,
        // setPosition,
        timeUnitsState,
        count: countArr
    } = useContext(AnalyticstimesContext);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);

    let maxValue = 1002;

    const [timesState, setTimeState] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [positionState, setPositionState] = useState(position);
    const [step, setStep] = useState(maxValue / (countItems));
    const [disabled, setDisabled] = useState(true);
    const [value, setValue] = useState(0);
    const [count, setCount] = useState({ start: 0 });

    const changeRound = (typeRound) => {
        let newPosition;
        switch (typeRound) {
            case "+":
                let newCount = Math.min(count.start + countItems, (timesState.length - 1) - countItems);
                newPosition = Math.round(newCount + (countItems / 2));
                setPosition(newPosition);
                return setCount({ start: newCount });
            case "-":
                let decreasedCount = Math.max(count.start - countItems, 0);
                newPosition = Math.round(decreasedCount + (countItems / 2));
                setPosition(newPosition);
                return setCount({ start: decreasedCount });
        }
    }

    const setNewItemCurrent = (value, start, positionCurrent) => {
        let position = Math.round(value / step) + start;
        setPositionState(position);
        setPosition(position ? position : 0);
    }

    useEffect(() => {
        if (times || timesReal || timesCompared) {
            if ((type === "expected" || type === "compared" && !real) && times?.length > 0) {
                setTimeState(times);
                countItems = countItems > times?.length ? times.length - 1 : countItems;
                setStep(maxValue / (countItems));
            } else if ((type === "real" || type === "compared" && real) && timesReal?.length > 0) {
                setTimeState(timesReal);
                countItems = countItems > timesReal?.length ? timesReal?.length - 1 : countItems;
                setStep(maxValue / (countItems));
            }
        }
    }, [times, timesReal, timesCompared]);

    useEffect(() => {
        if (setPosition) {
            setPosition(0);
        }
        setPositionState(0);
        setValue(0);
        setCount({ start: 0 });
    }, [timeUnitsState]);

    const handleChange = (value) => {
        let newCountstart;
        if (value === maxValue && count.start + countItems < timesState.length - 1) {
            setDisabled(false);
            newCountstart = (count.start + countItems) > (timesState.length - (countItems - 1)) ? timesState.length - (countItems + 1) : count.start + (countItems);
            setCount({ ...count, start: count.start + 1, });

            setNewItemCurrent(step * (countItems - (newCountstart - count.start)), newCountstart, position);
            setTimeout(() => {
                setValue(step * (countItems - 1));
                setDisabled(true);
            }, 100);
        }
        else if (value > 0) {
            setValue(value);
            setNewItemCurrent(value, count.start);
        } else {
            setDisabled(false);
            newCountstart = count.start - 1 >= 0 ? count.start - 1 : 0
            setCount({ ...count, start: newCountstart });
            setNewItemCurrent(count.start - 1 >= 0 ? step * 1 : 0, newCountstart);
            setTimeout(() => {
                setValue(count.start - 1 >= 0 ? step * 1 : 0);
                setDisabled(true);
            }, 100);
        }
    }

    useEffect(() => {
        let newCountstart = count.start;
        let newValue;
        if (position >= 0 && step >= 0 && countItems > 0) {
            if (position <= count.start) {
                /// like this
                while (position < newCountstart) {
                    newCountstart = Math.max(newCountstart - countItems, 0);
                }
                setCount({ ...count, start: newCountstart });
                newValue = position === 0 ? 0 : (position - newCountstart) * step;
                setValue(newValue);
                /////
            } else if (position >= count.start + countItems) {

                while (position >= newCountstart + countItems && newCountstart < (timesState.length - 1) - countItems) {
                    newCountstart = Math.min(newCountstart + countItems, timesState.length - 1 - countItems);
                }
                setCount({ ...count, start: newCountstart });
                newValue = (position - newCountstart) * step;
                setValue(newValue);

            } else {
                newValue = step * (position - count.start);
                setValue(newValue);
            }
        }
    }, [position, positionState, countItems, timesState]);

    useEffect(() => {
        setCount({ start: 0 })
    }, [type]);

    const Component = (
        <View style={styles.container}>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <View style={{ width: "1.5vw", alignItems: "center", justifyContent: "center" }}>
                    <TouchableOpacity
                        disable={count?.start === 0}
                        style={{ paddingLeft: "0.3vw" }} onPress={() => changeRound("-")}>
                        <MaterialIcons name='arrow-back-ios' color={count?.start === 0 ? color.border : color.text} size={"1vw"} />
                    </TouchableOpacity>
                </View>
                <View style={styles.timesView} ref={timeRef} >
                    {timesState?.length > 0 && timesState?.map((e, i) => {
                        let date1, data2;
                        if (type === "real" || (type === "compared")) {
                            date1 = new Date(e.date).toString();
                            data2 = new Date(timesState[position >= 0 ? position : 0]?.date).toString();
                        } else {
                            date1 = e?.time;
                            data2 = timesState[position >= 0 ? position : 0]?.time;
                        }
                        if (i >= count.start && i <= count.start + countItems) {
                            return (
                                <Pressable
                                    ref={(ref) => {
                                        ref && !scrollRef.current.find((e) => e === ref) && scrollRef.current.push(ref);
                                        // if (!ref) return;
                                        // if (position >= 0 && data2 === date1) {
                                        //     let t = ref.offsetLeft;
                                        //     if (document.getElementById("scroll-time") && document.getElementById("scroll-time").scrollLeft !== t) {
                                        //         document.getElementById("scroll-time").scrollLeft = t - 100;
                                        //     }
                                        // }
                                    }}
                                    style={[styles.timeBtn, { backgroundColor: i === position ? background.loginFooter : "", }]}
                                    key={i}
                                    onPress={() => {
                                        setPosition(i);
                                        setPositionState(i);
                                        scrollRef?.current[i]?.scrollIntoView({ inline: "center", block: "nearest" });
                                    }}>
                                    <Text style={[styles.filterText, { color: color.infoHeaderNavText, fontFamily: i === position ? "Catamaran_700Bold" : "Catamaran_300Light" }]}>{type === "expected" && "T"}{e.time}</Text>
                                </Pressable>
                            )
                        }
                    })}
                </View>
                <View style={{ width: "1.5vw", alignItems: "center", justifyContent: "center" }}>
                    <TouchableOpacity
                        style={{ paddingRight: "0.3vw" }}
                        disabled={(count.start + countItems) >= (timesState?.length - 1)}
                        onPress={() => changeRound("+")}>
                        <MaterialIcons name='arrow-forward-ios' color={(count.start + countItems) >= (timesState?.length - 1) ? color.border : color.text} size={"1vw"} />
                    </TouchableOpacity>
                </View>
            </View>
            <View style={{ backgroundColor: "#302B34", width: "100%", height: "1.8vw", position: "absolute", top: "2vw" }} />
            {timeRef.current && <View style={{ width: timeRef.current.offsetWidth }}>
                {scrollRef.current && <Rangeslider
                    className={`slider${id}`}
                    // disabled={!disabled}
                    id={"range-slider"}
                    indicator={id}
                    max={maxValue}
                    min={0}
                    width={Math.max(vwToPx(3.5), scrollRef.current[0]?.getBoundingClientRect()?.width + 9.8)}
                    onChange={(e) => {
                        handleChange(e);
                        return false;
                    }}
                    step={step}
                    value={value >= 0 ? value : null}
                />}
            </View>}
        </View>
    )

    return flag ? {
        Component: (
            Component
        ),
        currentTime: timesState?.length > 0 && timesState[position],
        times: timesState,
        scrollRef,
        position: scrollRef[position],
        itemPosition: position,
        scroll,
    } : Component
}

const stylesR = () => {
    return StyleSheet.create({
        container: {
            width: "100%",
            alignItems: "center"
        },
        timesView: {
            width: "fit-content",
            height: "2vw",
            // justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row"
        },
        filterText: {
            fontSize: "0.9vw",
        },
        timeBtn: {
            // marginHorizontal: widthS * 5,
            // marginVertical: heightS * 6,
            // padding: widthS * 2,
            // borderRadius: widthS * 3,
            marginVertical: "0.2vw",
            marginHorizontal: "0.4vw",
            minWidth: "3.2vw",
            borderRadius: "0.3vw",
            alignItems: "center",
            justifyContent: "center"
        },
        indicator: {
            backgroundColor: "purple",
            marginLeft: "1.5vw",
            width: "0.3vw",
            height: "1vw",
            position: "absolute"
        }
    })
}
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTimeUnits } from '../../../../modules/createTimeColumns';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
///////////////////////////////////////
import { createArrInteraction } from '../../../../modules/BuildMapChat';
import {
    URL_PATH,
    GET_MAP_STATISTICS
} from "@env";
import {
    F_PHPSESSID,
    USER_ID_IN_GAME,
    _CHAT_MESSAGE_,
    _CREATOR_ID_,
    _CURRENT_USER_ID_,
    _F_PHPSESSID_,
    _GROUPS_COUNT_,
    _GROUP_ID_,
    _GROUP_MEMBERS_,
    _GROUP_NAME_,
    _ID_,
    _LAST_ID_,
    _SENDER_ID_,
    _STATUS_,
    _USER_ID_,
} from "../../../../../config.inc";
import { AnalyticsContext } from '../../../../../ContextData/Analytics';
////////////////////////////////////////////////////
export const InteractionMapFuncContext = createContext()

export default function InteractionMapFuncProvider(props) {
    /////////////////////////////////////////////////////////////////////////////////////
    const { realMapItems,
        repMapItems,
        mvpItems, } = useContext(AnalyticsContext);

    const actionSelectSelector = useSelector(state => state.analytics.filter.actions);
    const realPlayers = useSelector(state => state.players.realPlayers);
    const playersSelector = useSelector(state => state.gameInfoArr.gameInfoArr.players);
    const playersSelectSelector = useSelector(state => state.analytics.filter.players);
    const eventsSelectSelector = useSelector(state => state.analytics.filter.events);
    const analyticsFlag = useSelector(state => state.analytics.flags.flag);
    //////////////////////////////////////////////////////////////
    // selector
    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

    const sendMailsSelector = useSelector(state => state.mailArr.sentMails);
    const mailsSelector = useSelector(state => state.mailArr.messages);
    const socialSelector = useSelector(state => state.socialMedia.socialMediaArr);
    const chatSelector = useSelector(state => state.chatArr.allMessages);
    const challengesSelector = useSelector(state => state.actionsArr.actionsArr);

    const actionsArrSelector = useSelector((state) => state.swimlane.actions);
    const eventsSelector = useSelector((state) => state.events.events);
    // select filter selector \\
    // end - selector\\
    // state - data
    const [mails, setMails] = useState([]);
    const [social, setSocial] = useState([]);
    const [chat, setChat] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [players, setPlayers] = useState([]);
    const [events, setEvents] = useState([]);
    const [actions, setActions] = useState([]);
    //\\
    // state - data to show \\
    // expected \\
    const [times, setTimes] = useState([]);
    const [arrScore, setArrScore] = useState([]);
    const [maxCount, setMaxCount] = useState(0);
    const [currentDate, setCurrentDate] = useState();
    // real \\
    const [timesReal, setTimesReal] = useState([]);
    const [arrScoreReal, setArrScoreReal] = useState([]);
    const [maxCountReal, setMaxCountReal] = useState(0);
    const [currentDateReal, setCurrentDateReal] = useState();
    //\\
    const [refresh, setRefresh] = useState(false);
    const [filterPlatform, setFilterPlatform] = useState("mail,sent,chat,social,challenges,");

    // select filter
    useEffect(() => {
        setActions(actionsArrSelector);
        setRefresh(!refresh);
    }, [actionsArrSelector]);

    useEffect(() => {
        if (Array.isArray(sendMailsSelector) && sendMailsSelector.length > 0) {
            setMails([...sendMailsSelector.filter((e) => {
                if (e?.event?.name) {
                    if (eventsSelectSelector.indexOf(e?.event?.id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(!refresh);
        }
    }, [sendMailsSelector.length, eventsSelectSelector]);

    useEffect(() => {
        if (Array.isArray(mailsSelector) && mailsSelector.length > 0) {
            setMails([...mailsSelector.filter((e) => {
                if (e?.event?.name) {
                    if (eventsSelectSelector.indexOf(e?.event?.id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(!refresh);
        }

    }, [mailsSelector.length, eventsSelectSelector]);

    useEffect(() => {
        if (challengesSelector.length > 0) {
            setChallenges([...challengesSelector.filter((e) => {
                if (e?.action?.event_id) {
                    if (eventsSelectSelector.indexOf(e?.action?.event_id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(!refresh);
        }
    }, [challengesSelector, eventsSelectSelector]);

    useEffect(() => {
        if (gameInfo?.players?.length > 0) {
            let arr = [...players];
            arr = gameInfo.players.filter((e) => playersSelectSelector.indexOf(e.id) >= 0 && e.permission_for_game === "15");
            setPlayers(arr);
            setRefresh(!refresh);
        }
    }, [playersSelectSelector, gameInfo?.players]);
    //\\

    useEffect(() => {
        if (socialSelector.length > 0) {
            setSocial(socialSelector);
            setRefresh(!refresh);
        }
    }, [socialSelector.length]);

    useEffect(() => {
        if (chatSelector.length > 0) {
            setChat(chatSelector);
            setRefresh(!refresh);
        }
    }, [chatSelector.length]);

    useEffect(() => {
        setEvents(eventsSelector);
        setRefresh(!refresh);
    }, [eventsSelector.length]);

    useEffect(() => {
        setRefresh(!refresh);
    }, [filterPlatform]);

    useEffect(() => {
        setRefresh(!refresh);
    }, [realMapItems, repMapItems, actionSelectSelector]);

    useEffect(() => {
        setRefresh(!refresh);
    }, [mvpItems, repMapItems, realMapItems]);

    const createArrStatistics = (timeUnits, players, events, challenges, actions, mails, social, chat, typeArr, type, timeFilter, nodes, real, nodesExpected, mvpItems) => {
        let times = [];
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        let timeArr = [];
        switch (type) {
            case "expected":
                if (actions.length > 0) {
                    if (players.length > 0 && nodes?.length > 0) {
                        return addScoreToPlayerExpected(nodes, actions, timeFilter, timeArr, type);
                    }
                } else {
                    setMaxCount(0);
                    return setArrScore([]);
                }
                return;
            case "real":

                if (players.length > 0 && nodes?.length > 0) {
                    return addScoreToPlayers(nodes, mvpItems); //, lastTime
                } else {
                    setMaxCountReal(0);
                    setArrScoreReal([]);
                }
                return;
            case "compared":
                if (players.length > 0 && nodes?.length > 0) {
                    addScoreToPlayers(nodes, mvpItems);//, lastTime
                    return addScoreToPlayerExpected(nodesExpected, actions, timeFilter, type);//, lastTime, type
                }
                return;
        }
    }
    // chat, challenges, mails, social, typeArr, type, null, timeFilter
    const createTimesCurrent = (timeUnits, chat, challenges, mails, social, typeArr, type, countSteps, time) => {
        let timeArr = [];
        let { firstTime, endTime } = findLastTime(chat, mails, challenges, social);
        if (firstTime && timeUnits) {
            timeArr = setTimeUnits({ timeUnits: timeUnits, time: firstTime }, endTime, typeArr, +countSteps);
            if (type === "compared") {
                timeArr.forEach((e, i) => e.step = i + 1);
                setTimes({ count: typeArr > 0 ? typeArr : 1, times: timeArr });
                setCurrentDate(timeArr[0]);
            }
            setTimesReal({ count: typeArr > 0 ? typeArr : 1, times: timeArr });
            setCurrentDateReal(time ? time : timeArr[0]);
        }
        return timeArr;
    }

    const checkDate = (itemDate, date) => {
        let itemDateCurrent = new Date(itemDate);
        if (itemDateCurrent <= date) {
            return true;
        }
        return false;
    }

    const addScoreToPlayerExpected = async (nodes, arr, currentDateState, times, type) => {
        return createInterctionMap("expected", nodes);
    }

    const checkTimeMessage = (id, date, arr) => {
        let flag = false;
        arr?.length > 0 && arr.forEach((e) => {
            if (e.message_id === id && +e.time <= date) {
                flag = true;
            }
        });
        return flag;
    }
    const checkTimeMessageReal = (id, dateCurrent, dateCurrent1, mails, chat) => {
        let flag = false;
        if (mails?.length > 0) {
            mails.forEach((mail, index) => {
                let date = new Date(`${mail.time} gmt`);
                date.setSeconds(0);
                if (mail.id === id) {
                    if (dateCurrent1) {
                        if (date < dateCurrent1) {
                            flag = true;
                        }
                    } else {
                        flag = true;
                    }
                }
            });
        }
        if (chat?.length > 0) {
            chat.forEach((chat, index) => {
                let date = new Date(`${chat.time} gmt`);
                date.setSeconds(0);
                if (chat.id === id) {
                    if (dateCurrent1) {
                        if (date < dateCurrent1) {
                            flag = true;
                        }
                    } else {
                        flag = true;
                    }
                }
            });
        }
        return flag;
    }

    const addScoreToPlayers = async (nodes, edges) => {
        return createInterctionMap("real", nodes, edges);
        // }
    }


    const createtimes = (times, arr, type) => {
        let arrN = [...times];
        if (arr.length > 0) {
            if (type === "chat") {
                arr?.forEach((e) => {
                    e.newMsg?.forEach((e) => {
                        arrN.push(e.time);
                    });
                    e.msgHistory?.forEach((e) => {
                        arrN.push(e.time);
                    });
                });
            } else {
                arr?.forEach((e) => {
                    arrN.push(e.time);
                });
            }
        }
        return arrN;
    }

    const returnIfIsThefirstOrLast = (timeCurrent, time, type) => {
        switch (type) {
            case "start":
                if (!timeCurrent) {
                    timeCurrent = new Date(time);
                } else if (time.getTime() < timeCurrent.getTime()) {
                    timeCurrent = new Date(time);
                }
                return timeCurrent;
            case "end":
                if (!timeCurrent) {
                    timeCurrent = new Date(time);
                } else if (time.getTime() > timeCurrent.getTime()) {
                    timeCurrent = new Date(time);
                }
                return timeCurrent;
        }
    }

    const findLastTime = (chatP, mailsP, challengesP, socialP) => {
        let firstTime;
        let endTime;
        if (challengesP?.length > 0) {
            challengesP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.action.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.action.time}`), 'end');
            });
        }
        if (mailsP?.length > 0) {
            mailsP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time}`), 'end');
            });
        }
        if (socialP?.length > 0) {
            socialP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time} gmt`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time} gmt`), 'end');
            });
        }
        if (chatP?.length > 0) {
            chatP?.forEach((e) => {
                firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time}`), "start");
                endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time}`), 'end');
            });
        }
        return {
            firstTime,
            endTime
        };
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////
    const dispatch = useDispatch();



    const createInterctionMap = (type, items, mvpItems) => {
        let res =
            createArrInteraction(
                type,// expected | real 
                items,// nodes
                filterEmptys(playersSelectSelector?.split(",").map((e) => e.trim())),// players
                filterEmptys(actionSelectSelector.split(",").map((e) => e.trim())),
                filterEmptys(eventsSelectSelector?.split(",").map((e) => e.trim())),
                createPlayersObject(playersSelectSelector, playersSelector),
                realPlayers?.length > 0 ? [...new Array(realPlayers.length)].map((e, i) => e = `${realPlayers[i].id}, `).toString() : "",
                mvpItems
            );
        if (type === "real") {
            return setArrScoreReal({ nodes: res.nodes, edges: res.edges });
        } else {
            return setArrScore({ nodes: res.nodes, edges: res.edges });
        }
    }

    const createPlayersObject = (filter, players) => {
        let obj = {};
        let temp = filter.split(",");
        temp.forEach((e) => {
            playersSelector.forEach((pl) => {
                if (e === pl.id) {
                    obj[e] = pl.name_for_game;
                }
            })
        });
        return obj;
    }

    const filterEmptys = (arr) => {
        return arr.filter((element) => {
            return element !== null && element !== undefined && element.length > 5 && element?.match(/[a-zA-Z]/)?.length > 0;
        })
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const filterStatistics = (timeUnits, typeArr, type, time, real) => {
        if (type === "expected") {
            if (repMapItems?.length > 0 && actions.length > 0) {
                createArrStatistics(
                    timeUnits,
                    players,
                    events,
                    challenges,
                    actions,
                    mails,
                    social,
                    chat,
                    typeArr,
                    type,
                    time,
                    repMapItems,
                    real
                )
            } else {
                setMaxCount(0);
                setArrScore([]);
            }
        } else if (type === "real" || type === "compared") {
            if (mvpItems && (mails.length > 0 || chat?.length > 0 || social.length > 0 || challenges.length > 0)) {
                createArrStatistics(
                    timeUnits,
                    players,
                    events,
                    challenges,
                    actions,
                    mails,
                    social,
                    chat,
                    typeArr,
                    type,
                    time,
                    realMapItems,
                    real,
                    repMapItems,
                    mvpItems
                )
            } else {
                setMaxCountReal(0);
                setArrScoreReal([]);
            }
        }
    }

    return (
        <InteractionMapFuncContext.Provider
            value={{
                arrScore,
                arrScoreReal,
                times,
                timesReal,
                maxCount,
                maxCountReal,
                filterPlatform,
                setFilterPlatform,
                filterStatistics,
                setCurrentDate,
                setCurrentDateReal,
                currentDate,
                currentDateReal,
                refresh,
                setRefresh
            }}
        >
            {props.children}
        </InteractionMapFuncContext.Provider>
    )
}

import { FlatList, ScrollView, StyleSheet, Text, View } from 'react-native'
import React, { createRef, memo, useEffect, useMemo, useRef, useState } from 'react'
import UseResponsiveSize from '../../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import { useSelector } from 'react-redux';
import { t } from 'i18n-js';

export default function WordMessages({ wordsMessages, word, stopPlayScroll, type, maximize }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);

    const conatinerRef = useRef(null);

    const [wordsMessagesState, setWordsMessagesState] = useState([]);
    const [wordState, setWordState] = useState([]);

    const printBody = (text, word) => {
        let newText = text.replace(word, "$");
        if (newText.indexOf(" ") > 0) {
            return newText.split(" ").forEach((e) => {
                return (
                    <Text style={{ backgroundColor: e === "$" ? "#FCF000" : "" }}>{e === "$" ? word : e}</Text>
                )
            })
        } else {
            return <Text style={{ backgroundColor: "" }}>{word}</Text>
        }
    }

    const MarkedText = memo(({ textToMark, markedWords, markerColor }) => {
        // Split on spaces and punctuation (you might need to adjust the regex for specific cases)
        const words = textToMark.split(/(\s+|[.,!?;])/).filter(Boolean);

        return (
            <span>
                {words.map((word, index) => {
                    // Remove punctuation from the word for comparison
                    const wordWithoutPunctuation = word.replace(/[.,!?;]+$/, '');
                    if (markedWords.includes(wordWithoutPunctuation.toLowerCase())) {
                        return (
                            <mark key={index} style={{ backgroundColor: markerColor }}>
                                {word}
                            </mark>
                        );
                    } else {
                        return word;
                    }
                })}
            </span>
        );
    });

    useEffect(() => {
        if (Array.isArray(wordsMessages)) {
            setWordsMessagesState([...wordsMessages]);
            setWordState(word);
        }
    }, [wordsMessages, word]);

    useEffect(() => {
        if (conatinerRef.current) {
            conatinerRef.current.addEventListener("mousemove", (e) => {
                stopPlayScroll(false);
            });
            conatinerRef.current.addEventListener("mouseleave", (e) => {
                stopPlayScroll(true);
            });
        }
    }, [conatinerRef.current, wordsMessages]);

    return (
        <View style={[styles.container, type === "compared" && styles.containerCompared]} >
            {wordsMessages?.length > 0 &&
                <View style={[styles.table, { backgroundColor: background.infoBox }]} ref={conatinerRef}>
                    <View style={[styles.header, { backgroundColor: background.infoHeader, }]}>
                        <Text style={[styles.textHeader, { width: "30%", color: color.infoHeaderNavText }]}>{t("from")}</Text>
                        <Text style={[styles.textHeader, { width: "30%", color: color.infoHeaderNavText }]}>{t("to")}</Text>
                        <Text style={[styles.textHeader, { width: "30%", color: color.infoHeaderNavText }]}>{t("body")}</Text>
                    </View>
                    <FlatList
                        nativeID='scroll-words-messages'
                        style={{ width: "100%", flex: 1, maxHeight: heightS * 450, borderBottomLeftRadius: widthS * 19, borderBottomRightRadius: widthS * 19 }}
                        contentContainerStyle={{}}
                        data={wordsMessagesState}
                        keyExtractor={item => item.id}
                        renderItem={({ item: message, index }) => {
                            let body = document.createElement("p");
                            body.innerHTML = message.body;
                            return (
                                <View key={index} style={[styles.row, { backgroundColor: wordsMessages.length === 1 ? "#302B34" : (index % 2 === 0 ? "" : "#302B34") }]}>
                                    <Text style={[styles.textHeader, { width: "30%", color: color.infoHeaderNavText }]}>{message.from}</Text>
                                    <Text style={[styles.textHeader, { width: "30%", color: color.infoHeaderNavText }]}>{message.to}</Text>
                                    <Text style={[styles.textHeader, { width: "30%", color: color.infoHeaderNavText }]} nativeID="body-word">
                                        <MarkedText
                                            textToMark={body.innerText}
                                            isCaseSensitive={true}
                                            markedWords={[wordState?.toLowerCase(), wordState?.toUpperCase(), wordState]}
                                            markerColor='#FCF000'
                                        />
                                    </Text>
                                </View>
                            )
                        }}
                    />
                </View>}
        </View>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            flex: 0.9,
            marginLeft: widthS * 10,
            height: "41vw",
            maxHeight: "41vw",
            width: "98%",
        },
        containerCompared: {
            marginLeft: 0,
            height: "41vw",
            maxWidth: "49%",
        },
        table: {
            width: "100%",
            marginTop: heightS * 26,
            borderRadius: widthS * 19,
            flex: 1,
            shadowColor: "black",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.7,
            shadowRadius: 2
        },
        header: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: heightS * 5,
            borderTopLeftRadius: widthS * 19,
            borderTopRightRadius: widthS * 19,
        },
        textHeader: {
            textAlign: "center",
        },
        row: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: heightS * 5
        }
    });
} 


export const listenerAdd = (popUpRef, flag, setFlag) => {
    return (e) => {
        if (flag && popUpRef?.current && !popUpRef?.current.contains(e.target)) {
            setFlag();
        }
    }
}

export const listenerAddText = (popUpRef, flag, setFlag, textCheck) => {
    return (e) => {
        if (flag && !textCheck && popUpRef?.current && !popUpRef?.current.contains(e.target)) {
            setFlag();
        }
    }
}

export const listenerAddWith = (popUpRef, flag, flag2, setFlag) => {
    return (e) => {
        if (flag && popUpRef?.current && !popUpRef?.current.contains(e.target) && !flag2) {
            setFlag();
        }
    }
}

export const listenerAddBT = (popUpRef, btnRef, flag, setFlag) => {
    return (e) => {
        if (flag && popUpRef?.current && btnRef?.current && !btnRef?.current?.contains(e.target) && (!popUpRef?.current?.contains(e.target) || !popUpRef?.current?.contains(e.target))) {
            setFlag();
        }
    }
}
export const listenerBTAndPopup = (popUpRef, btnRef, flag, setFlag) => {
    return (e) => {
        if (
            flag &&
            popUpRef?.current &&
            btnRef?.current &&
            !btnRef?.current?.contains(e.target) &&
            !popUpRef?.current?.contains(e.target)
        ) {
            setFlag(false); // Ensure to pass false to close the popup
        } else if (
            flag &&
            (btnRef?.current?.contains(e.target) || popUpRef?.current?.contains(e.target))
        ) {
            setFlag(flag); // Keep the popup open if mouse is over the button or popup
        }
    };
};



export const listenerAddTwoBT = (popUpRef, btnRef1, btnRef2, flag, setFlag) => {
    return (e) => {
        if (flag && popUpRef?.current && !btnRef1?.current.contains(e.target) && !popUpRef?.current.contains(e.target) && !btnRef2.current.contains(e.target)) {
            setFlag();
        }
    }
}

export const listenerAddOut = (popUpRef, outsidePopUp, flag, setFlag) => {
    return (e) => {
        if (flag && popUpRef?.current && !popUpRef?.current.contains(e.target) && outsidePopUp?.current.contains(e.target)) {
            setFlag();
        }
    }
}
import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  StyleSheet,
  View,
  Modal,
  Text,
  Pressable,
  Image,
  ScrollView,
  TouchableOpacity
} from "react-native";
import "./scroll.css";
import { useDispatch, useSelector } from "react-redux";
import AddFile from "./AddNewFile";
import FileItem from "./FileItem";
import { t } from "i18n-js";
import { FontAwesome } from '@expo/vector-icons';
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import { removeType, setTypeMinimize } from "../../../redux/actions";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";
import onClickEndGameCheck from "../../Elements/onClickEndGameCheck";

export default function Files({ setFlag, refC, setDrag }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);
  const { checkEnd } = onClickEndGameCheck();

  const refItem = useRef([]);

  const dispatch = useDispatch();

  const popupRef = useRef(null);
  const popupContainerRef = useRef(null);
  const files = useSelector((state) => state.filesArr.filesArr);
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);
  const minimizePopups = useSelector((state) => state.minimize.minimize);
  const playerPermission = useSelector((state) => state.gameInfoArr.permission);

  const [showAddFile, setShowAddFile] = useState(false);
  const [filesArr, setFileArr] = useState([]);
  const [index, setIndex] = useState();

  useMemo(() => {
    if (popupRef?.current) {
      console.log(popupRef.current);
      console.log(popupRef.current.getBoundingClientRect());
    }
  }, [popupRef.current])

  useMemo(() => {
    setFileArr([...files]);
  }, [files]);

  return (
    <>
      <View
        style={[
          styles.main,
          { backgroundColor: background.popup1, borderColor: color.border },
        ]}
        ref={popupRef}
      >
        <View style={{ width: "100%", height: "100%" }}>
          <TouchableOpacity style={{ cursor: "grabbing" }} onPressIn={() => setDrag(true)}>
            <View style={[styles.header, { borderBottomColor: color.border }]}>
              <View style={styles.headerLeft}>
                <View
                  style={[
                    styles.logo_view,
                    { backgroundColor: background.mailLogoColor },
                  ]}
                >
                  <Image source={{ uri: images.files }} style={styles.logo} />
                </View>
                <Text selectable={false} style={styles.textLogo}>
                  {t("menu_my-files")}
                </Text>
              </View>
              {playerPermission !== "17" && playerPermission !== "10" && <Pressable
                onPress={() => checkEnd(() => setShowAddFile(true))}
                style={[styles.new_btn, { backgroundColor: background.loginFooter }]}
              >
                <Text selectable={false} style={styles.new_btn_text}>
                  +{t("new")}
                </Text>
              </Pressable>}
              <HeaderPopupButtons
                close={() => { setFlag(); dispatch(removeType("files")) }}
                minimize={() => dispatch(setTypeMinimize({ name: "files", type: 1, show: false }))}
              />
            </View>
          </TouchableOpacity>
          <ScrollView
            nativeID="file"
            style={styles.body_c}
            contentContainerStyle={styles.body_m}
          >
            {filesArr.length > 0 &&
              filesArr.map((e, i) => {
                if (e && e.type) {
                  return (
                    <FileItem
                      item={e}
                      key={i}
                      index={index}
                      refItem={refItem}
                      zIndex={index === i ? 4 : 3}
                      setIndex={(i) => setIndex(i)}
                      i={i}
                    />
                  );
                }
              })}
          </ScrollView>
        </View>
      </View>
      {showAddFile && (
        <AddFile flag={showAddFile} setFlag={() => setShowAddFile(false)} refC={refC} />
      )}
      {filesArr[index] &&
        (popupRef.current.getBoundingClientRect().height) > ((refItem.current[index].getBoundingClientRect().y +
          refItem.current[index].getBoundingClientRect().height) -
          (popupRef.current.getBoundingClientRect().top + heightS * 10)) &&
        (
          <View nativeID="files-des" style={[styles.des_container, {
            top: refItem.current[index].getBoundingClientRect().y +
              refItem.current[index].getBoundingClientRect().height -
              (popupRef.current.getBoundingClientRect().top + heightS * 10),
            left: refItem.current[index].getBoundingClientRect().left -
              popupRef.current.getBoundingClientRect().left + widthS * 5,
          }]}>
            <View
              style={[
                styles.traingular,
                { borderBottomColor: background.filesDes },
              ]}
            />
            <View
              style={[
                styles.des_view,
                { backgroundColor: background.filesDes },
              ]}
            >
              <Text
                selectable={false}
                style={[styles.des_text, { color: color.filesText }]}
              >
                {filesArr[index].description}
              </Text>
            </View>
          </View>
        )}
    </>
  );
}
const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    main: {
      display: "flex",
      alignItems: "center",
      zIndex: 10,
      backgroundColor: "#00000",
      flexDirection: "column",
      fontFamily: "Roboto",
      borderRadius: widthS * 15,
      height: "100%",
    },
    // header: {
    // 	display: "flex",
    // 	flexDirection: "row",
    // 	alignItems: "center",
    // 	borderBottomWidth: 1,
    // 	borderStyle: "solid",
    // 	paddingVertical: heightS * 3,
    // 	borderTopRightRadius: widthS * 12,
    // 	borderTopLeftRadius: widthS * 12,
    // 	paddingLeft: widthS * 15,
    // 	position: "relative",
    // },
    // logo_view: {
    // 	width: widthS * 26,
    // 	height: widthS * 26,
    // 	borderRadius: widthS * 20,
    // 	display: "flex",
    // 	alignItems: "center",
    // 	justifyContent: "center"
    // },
    // logo: {
    // 	width: widthS * 13,
    // 	height: widthS * 13,
    // 	resizeMode: "contain",
    // },
    // logo_text: {
    // 	color: "white",
    // 	fontSize: widthS * 12,
    // 	marginLeft: widthS * 10,
    // 	fontWeight: "600",
    // },
    // x: {
    // 	position: "absolute",
    // 	top: heightS * 5,
    // 	right: widthS * 10,
    // },
    // x_text: {
    // 	fontWeight: "350",
    // 	fontSize: widthS * 9,
    // 	color: "white"
    // },
    header: {
      paddingLeft: widthS * 16,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: heightS * 4,
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: widthS * 13,
      height: widthS * 13,
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 15,
      paddingLeft: widthS * 10,
    },
    x: {
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 14,
    },
    new_btn: {
      position: "absolute",
      top: widthS * 6,
      right: "5vw",
      borderRadius: widthS * 5,
      paddingHorizontal: widthS * 7,
      paddingVertical: heightS * 3,
    },
    new_btn_text: {
      color: "#ffffff",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
    },
    body_c: {
      marginVertical: heightS * 7,
      maxHeight: height > 600 ? heightS * 230 : heightS * 255,
    },
    body_m: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      paddingBottom: heightS * 30,
    },
    des_container: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      width: widthS * 110,
      zIndex: 11,
    },
    traingular: {
      width: 0,
      height: 0,
      borderLeftWidth: widthS * 8,
      borderRightWidth: widthS * 8,
      borderBottomWidth: widthS * 8,
      borderStyle: "solid",
      backgroundColor: "transparent",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      zIndex: 4,
      marginLeft: widthS * 22,
    },
    des_view: {
      marginTop: height - 2,
      width: widthS * 105,
      padding: widthS * 8,
      borderRadius: widthS * 3,
      minHeight: heightS * 30,
      maxHeight: heightS * 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 3,
    },
    des_text: {
      textAlign: "left",
      fontSize: widthS * 7,
      width: widthS * 90,
      fontFamily: "Catamaran_400Regular",
    },
  });
